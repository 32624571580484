import React, { ReactElement, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Image } from '../../../utils/image';

const FAQAccordion = (): ReactElement => {
    const [activeKey, setActiveKey] = useState(1);

    const data = [
        {
            id: 1,
            title: `How do I pay? Can I pay online?`,
            description:
                `Connect your customer's LDAP or Active Directory; synchronize user accounts to Universal Directory Connect your customer's LDAP or Active Directory; synchronize user accounts to Universal Directory Connect your customer's LDAP or Active Directory; synchronize user accounts to Universal Directory.`,
        },
        {
            id: 2,
            title: `How can i get started with IAM?`,
            description:
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .`,
        },
        {
            id: 3,
            title: `What's the purpose of IAM?`,
            description:
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .`,
        },
        {
            id: 4,
            title: `Why do I need cloud IAM?`,
            description:
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .`,
        },
        {
            id: 5,
            title: `What kinds of USSD short codes are there?`,
            description:
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .`,
        },
        {
            id: 6,
            title: `How can StackIAM help my business?`,
            description:
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .`,
        },
        {
            id: 7,
            title: `Is my information truly safe with just one single login?`,
            description:
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .`,
        },
        {
            id: 8,
            title: `What kinds of USSD short codes are there?`,
            description:
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .`,
        },
    ];

    const handleToggle = (id: number) => {
        if (id !== activeKey) {
            setActiveKey(id);
        }
    };

    return (
        <Row noGutters style={{ paddingTop: `3rem` }}>
            <Col xs="12">
                {data.map(item => (
                    <div key={item.id}>
                        <div>
                            <div
                                style={{
                                    display: `flex`,
                                    justifyContent: `space-between`,
                                    alignItems: `center`,
                                    cursor: `pointer`,
                                    border: `solid 0.5px #e9e9e9`,
                                    backgroundColor: `#fff8f0`,
                                    boxShadow: `0 5px 25px 0 rgba(243, 243, 243, 0.5)`,
                                    marginTop: `0.4rem`,
                                    marginBottom: `0.4rem`,
                                }}
                                onClick={() => handleToggle(item.id)}
                            >
                                <div
                                    style={{
                                        color: `#3a3a3a`,
                                        paddingTop: `1rem`,
                                        paddingBottom: `1rem`,
                                        paddingLeft: `2rem`,
                                    }}
                                >
                                    {item.title}
                                </div>
                                <div style={{ marginRight: `2rem` }}>
                                    <Image
                                        style={{
                                            height: `1.5rem`,
                                            width: `1.5rem`,
                                        }}
                                        src={
                                            activeKey === item.id
                                                ? `/images/minus.svg`
                                                : `/images/plus.svg`
                                        }
                                    />
                                </div>
                            </div>
                            {activeKey === item.id && (
                                <p
                                    style={{
                                        color: `#aab3c2`,
                                        padding: `2rem`,
                                    }}
                                >
                                    {item.description}
                                </p>
                            )}
                        </div>
                    </div>
                ))}
            </Col>
        </Row>
    );
};
export default FAQAccordion;
