import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';

interface HeadingSectionProps {
    heading: string;
    subheading?: string;
    desc?: string;
}

const HeadingSection = ({
    heading,
    subheading,
    desc,
}: HeadingSectionProps): ReactElement => (
    <Row noGutters>
        <Col
            xs="12"
            style={{
                textAlign: `center`,
                paddingTop: `3rem`,
                color: `#2e598f`,
                paddingBottom: `3rem`,
            }}
        >
            <div
                style={{
                    fontSize: `2rem`,
                    paddingBottom: `1rem`,
                }}
            >
                {heading}
            </div>
            {subheading && (
                <div
                    style={{
                        fontSize: `1.5rem`,
                        color: `#3c4043`,
                        fontWeight: `bold`,
                    }}
                >
                    {subheading}
                </div>
            )}
            {desc && (
                <p
                    style={{
                        padding: `1rem`,
                        paddingLeft: `5rem`,
                        paddingRight: `5rem`,
                        color: `#535b6c`,
                    }}
                >
                    {desc}
                </p>
            )}
        </Col>
    </Row>
);

export default HeadingSection;
