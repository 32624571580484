import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ColoredButton } from '../Buttons';
import { Image } from '../../../utils/image';

interface ContentHeroProps {
    heading: string;
    desc: string;
    buttonText?: string;
    imageUrl: string;
}

const ContentHero = ({
    heading,
    desc,
    buttonText,
    imageUrl,
}: ContentHeroProps): ReactElement => (
    <Row
        noGutters
        style={{
            backgroundImage: `linear-gradient(to bottom, #fffcf9, #fff8f0)`,
        }}
    >
        <Col md="12" lg="5" style={{ paddingLeft: `3rem`, paddingTop: `2rem` }}>
            <div style={{ fontSize: `3rem`, color: `#2e598f` }}>{heading}</div>
            <div
                style={{
                    paddingTop: `1rem`,
                    fontSize: `1.05rem`,
                    color: `#535b6c`,
                }}
            >
                {desc}
            </div>
            {buttonText && (
                <div style={{ paddingTop: `1rem`, paddingBottom: `1rem` }}>
                    <ColoredButton>{buttonText}</ColoredButton>
                </div>
            )}
        </Col>
        <Col md="12" lg="7" style={{ paddingLeft: `1rem`, paddingTop: `2rem` }}>
            <Image src={imageUrl} />
        </Col>
    </Row>
);

export default ContentHero;
