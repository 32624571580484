import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentHero from '../components/common/contentSections/ContentHero';
import FAQAccordion from '../components/common/contentSections/FAQAccordion';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import { Image } from '../utils/image';

const FAQ = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading=" Frequently Asked Questions"
                desc=" StackIAM allows you to integrate with other businesses and give your business partners access to information they might need."
                imageUrl="/images/faq-hero@3x.png"
            />
            <HeadingSection heading="Feel Free To Ask Question" />
            <Row noGutters style={{ marginBottom: `5rem` }}>
                <Col lg="8" md="12">
                    <FAQAccordion />
                </Col>
                <Col
                    lg="4"
                    md="12"
                    style={{
                        paddingLeft: `2rem`,
                        paddingRight: `2rem`,
                        paddingTop: `3rem`,
                        color: `#535b6c`,
                    }}
                >
                    <div style={{ fontSize: `1.5rem`, color: `#171717` }}>
                        Want to learn more?
                    </div>
                    <p>
                        Contact us if you want to learn more about StackIAM or
                        if you’d like to speak to our representative.
                    </p>
                    <div
                        style={{
                            fontSize: `1.5rem`,
                            color: `#171717`,
                            paddingTop: `1rem`,
                            paddingBottom: `1rem`,
                        }}
                    >
                        Contact Support
                    </div>
                    <div
                        style={{
                            display: `flex`,
                            paddingTop: `1rem`,
                            paddingBottom: `1rem`,
                        }}
                    >
                        <Image
                            style={{ height: `2rem`, width: `2rem` }}
                            src="/images/contact-us-mail.svg"
                        />
                        <div style={{ paddingLeft: `0.5rem` }}>
                            contact@stackiam.com
                        </div>
                    </div>
                    <div
                        style={{
                            display: `flex`,
                            paddingTop: `1rem`,
                            paddingBottom: `1rem`,
                        }}
                    >
                        <Image
                            style={{ height: `2rem`, width: `2rem` }}
                            src="/images/contact-us-location-pin.svg"
                        />
                        <div style={{ paddingLeft: `0.5rem` }}>
                            42, Mongomery road Sabo yaba, Lagos Nigeria
                        </div>
                    </div>
                    <div
                        style={{
                            display: `flex`,
                            paddingTop: `1rem`,
                            paddingBottom: `1rem`,
                        }}
                    >
                        <Image
                            style={{ height: `2rem`, width: `2rem` }}
                            src="/images/contact-us-mobile.svg"
                        />
                        <div style={{ paddingLeft: `0.5rem` }}>
                            +2348027272720 +2348027272720 +2348027272720
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </Layout>
);

export default FAQ;
